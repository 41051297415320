import React, { useState, useContext, useMemo, useEffect } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Icofont from "../../IcoFont";
import _ from "lodash";
import { ItemContext } from "../itemContext";
import { CartContext, MaintenanceContext } from "../../../context";
import { useTrans } from "../../../hooks";

const ItemModalFooter = () => {
  const transMsg = useTrans();
  const {
    id,
    title,
    localeShareId,
    price,
    image,
    specialInstructions,
    selectedModifiers,
    max,
    min,
    priceUnit,
    handleClose,
    setDisableAddToCart,
    totalModifiersRequired,
    disableAddToCart,
    availability,
  } = useContext(ItemContext);

  const { setCartLineItems, setCartTotals, cartLineItems } =
    useContext(CartContext);

  const { isUnderMaintenance } = useContext(MaintenanceContext);
  const [cartQty, setCartQty] = useState(1);

  useEffect(() => {
    if (availability === "soldout_today") {
      setDisableAddToCart(true);
      return;
    }
    if (_.isEmpty(selectedModifiers) && totalModifiersRequired === 0) return;
    const _required = _.filter(selectedModifiers, (v) => v.isRequire);
    if (totalModifiersRequired <= _required.length) setDisableAddToCart(false);
    else setDisableAddToCart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalModifiersRequired, selectedModifiers]);

  const IncrementItem = () => {
    if (cartQty < max) {
      setCartQty(cartQty + 1);
    }
  };

  const DecreaseItem = () => {
    if (cartQty > min + 1) {
      setCartQty(cartQty - 1);
    }
  };
  const unitPrice = useMemo(() => {
    const totalOptions = selectedModifiers
      ? _.sumBy(selectedModifiers.map((s) => s.item?.price || 0))
      : 0;

    return price + totalOptions;
  }, [selectedModifiers, price]);

  const total = useMemo(() => {
    return cartQty * unitPrice;
  }, [cartQty, unitPrice]);

  const AddToCart = () => {
    let fakeLineItemId = id;
    while (true) {
      fakeLineItemId = _.uniqueId(id);
      if (!cartLineItems[fakeLineItemId]) break;
    }

    setCartLineItems((c) => {
      return {
        ...c,
        [fakeLineItemId]: {
          item: {
            id,
            name: title,
            price, // base price
          },
          localeShareId,
          id: fakeLineItemId,
          image,
          title,
          total,
          unitPrice, // combined price with extras
          note: specialInstructions,
          choices: selectedModifiers,
          quantity: cartQty,
        },
      };
    });

    setCartTotals((c) => {
      return {
        ...c,
        qty: c.qty + cartQty,
        subtotal: c.subtotal + total,
        quantitiesPerItem: {
          ...c.quantitiesPerItem,
          [id]: (c.quantitiesPerItem?.[id] || 0) + cartQty,
        },
      };
    });

    handleClose();
  };

  //===========================================================
  return (
    <>
      {/* TODO: fix two line on mobile with icon */}

      <center>
        <span className="text-grey float-left pt-2 pl-1">
          {transMsg("quantity")}{" "}
        </span>
        <ButtonGroup className="float-right">
          <Button
            variant="light"
            onClick={DecreaseItem}
            // size="lg"
            className="minBtn"
          >
            <Icofont icon="minus" />
          </Button>
          <div className="addCartBtn">{cartQty}</div>
          <Button
            variant="light"
            onClick={IncrementItem}
            size="sm"
            className="addBtn"
            style={{ marginRight: "10px" }}
          >
            <Icofont icon="icofont-plus" />
          </Button>
        </ButtonGroup>
        <br />
        {/* TODO: FIX STICKY */}
        {isUnderMaintenance && (
          <>
            {" "}
            <br />
            <br />
            <div className="text-danger">
              {" "}
              {transMsg("underMaintenanceCartContent")}
            </div>
          </>
        )}
        <Button
          onClick={AddToCart}
          variant="warning"
          disabled={disableAddToCart || isUnderMaintenance}
          className="text-black font-14 add-cart-btn mt-4 bold fixed"
          style={{ borderRadius: "25px", padding: "0px" }}
        >
          {transMsg("addToCart")}- {priceUnit}
          {total?.toFixed(2)}
        </Button>{" "}
      </center>
    </>
  );
};

export default ItemModalFooter;
