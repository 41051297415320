import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import _ from "lodash";
import produce from "immer";
import { ItemContext } from "../itemContext";
import { useLocalization } from "../../../hooks";
import { useTrans } from "../../../hooks";

const Modifiers = () => {
  const transMsg = useTrans();
  const { locale: currentLocale } = useLocalization();

  const [selected, setSelected] = useState({});
  const {
    setSelectedModifiers,
    modifiers,
    priceUnit,
    setDisableAddToCart,
    setTotalModifiersRequired,
  } = useContext(ItemContext);
  // BEGIN MODAL

  useEffect(() => {
    if (_.isEmpty(selected)) setSelectedModifiers([]);
    else
      setSelectedModifiers(
        _.flatten(_.values(selected).map((v) => _.values(v)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (_.isEmpty(modifiers)) return;
    const _required = _.filter(modifiers, (v) => v[currentLocale].isRequire);
    setTotalModifiersRequired(_required.length);
    if (_required.length > 0) setDisableAddToCart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiers]);

  const onOptionSelected = (
    { modifier, item },
    name,
    type,
    checked,
    maxOptions,
    isRequire
  ) => {
    if (type === "radio")
      setSelected({
        ...selected,
        [name]: { [item.id]: { modifier, item, quantity: 1, isRequire } },
      });

    if (type === "checkbox") {
      setSelected(
        produce(selected, (draft) => {
          if (!draft[name]) draft[name] = {};

          if (checked && _.keys(draft[name]).length < maxOptions)
            draft[name][item.id] = { modifier, item, quantity: 1, isRequire };
          else delete draft[name][item.id];
        })
      );
    }
  };
  //===========================================================
  return (
    <>
      {!_.isEmpty(modifiers) && (
        <>
          {modifiers.map((v, i) => {
            const vLocale = v[currentLocale];
            return (
              <React.Fragment key={i}>
                <Form.Group controlId="formBasicCheckbox">
                  {/* {JSON.stringify(v)} */}
                  <div
                    className="py-3 pl-2"
                    style={{ backgroundColor: "#F6F6F6" }}
                  >
                    <h5
                      style={{
                        margin: 0,
                      }}
                    >
                      {vLocale.name}{" "}
                      {vLocale.isRequire && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </h5>
                    {vLocale.listType === "checkbox" && (
                      <p className="item-sub" style={{ margin: 0 }}>
                        {transMsg("selectUpTo")} {vLocale.maxOptions}
                      </p>
                    )}
                  </div>

                  <div className="mb-2"></div>
                  {v.items.map((itemV, itemI) => {
                    if (!itemV.item)
                      return <React.Fragment key={itemI}></React.Fragment>;
                    const itemLocale = itemV.item[currentLocale];
                    return (
                      <React.Fragment key={itemI}>
                        <div className="row py-2">
                          <div className="col-9">
                            <Form.Check
                              type={vLocale.listType}
                              name={vLocale.id}
                              id={itemLocale.id}
                              label={`${itemLocale.name}`}
                              checked={
                                selected?.[vLocale.id]?.[itemLocale?.id] ||
                                false
                              }
                              required={vLocale.isRequire}
                              onChange={({
                                target: { name, type, checked },
                              }) => {
                                onOptionSelected(
                                  {
                                    modifier: {
                                      id: vLocale.id,
                                      name: vLocale.name,
                                    },
                                    item: itemLocale,
                                  },
                                  name,
                                  type,
                                  checked,
                                  vLocale.maxOptions,
                                  vLocale.isRequire
                                );
                              }}
                            />
                          </div>
                          {itemLocale?.price > 0 && (
                            <div className="col-3 text-right not-bold font-14">
                              +{priceUnit}
                              {itemLocale.price.toFixed(2)}
                            </div>
                          )}
                        </div>
                      </React.Fragment>
                    );
                  })}
                </Form.Group>
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default Modifiers;
