import React, { useContext, useEffect, useMemo, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Scroll from "react-scroll";
import ItemContainer from "../../components/Item";

import { CartContext } from "../../context";
import { useUpdateCartQty } from "../../hooks";
import _ from "lodash";
import { SearchContext } from "../../context";

import SearchBar from "./searchBar";
import { usePriceFormat, useLocalization } from "../../hooks";

const OrderOnline = ({ menu }) => {
  const { locale: currentLocale } = useLocalization();

  const { cartLineItems } = useContext(CartContext);
  const [categories, setCategories] = useState([]);
  const [filterItems, setFilterItems] = useState({});
  const getQty = useUpdateCartQty();
  const { searchResult } = useContext(SearchContext);
  const { priceSymbol } = usePriceFormat();

  useEffect(() => {
    if (_.isEmpty(currentLocale)) return;
    if (_.isEmpty(searchResult)) {
      setFilterItems({});
      setCategories(menu.categories);
      return;
    }
    const catNames = _.keys(_.groupBy(searchResult, "categoryName"));
    setFilterItems(_.groupBy(searchResult, "id"));
    const _cats = _.filter(
      menu.categories,
      (c) => _.indexOf(catNames, c[currentLocale].name) > -1
    );
    setCategories(_cats);
  }, [searchResult, menu.categories, currentLocale]);

  const itemsQty = useMemo(() => {
    if (_.isEmpty(cartLineItems)) return {};
    let _itemsQty = {};
    _.map(cartLineItems, (v, k) => {
      if (_itemsQty[v.localeShareId]) _itemsQty[v.localeShareId] += v.quantity;
      else _itemsQty[v.localeShareId] = v.quantity;
    });
    return _itemsQty;
  }, [cartLineItems]);

  useEffect(() => {
    Scroll.Events.scrollEvent.register("begin", function () {});
    Scroll.Events.scrollEvent.register("end", function () {});
    Scroll.scrollSpy.update();
    return () => {
      Scroll.Events.scrollEvent.remove("begin");
      Scroll.Events.scrollEvent.remove("end");
    };
  }, []);

  // ================================================================
  return (
    <>
      {/* BEGIN MENU CAT */}
      <div className="cat-menu-div ontop" style={{ background: "white" }}>
        {/* <div className="recommendedArea searchMenuBarDiv">
       
        </div> */}
        <SearchBar />
        <div className="scrollmenu">
          {categories.map((v, i) => {
            return (
              <React.Fragment key={i}>
                <Scroll.Link
                  activeClass="active-menu-cat"
                  spy={true}
                  to={_.snakeCase(v[currentLocale]?.name)}
                  smooth={true}
                  offset={-250}
                  duration={250}
                  onClick={() => {}}
                  onSetActive={(e, a) => {}}
                  onSetInactive={(e) => {}}
                >
                  <span className="cat-name">{v[currentLocale]?.name}</span>
                </Scroll.Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <br />

      <section className="offer-dedicated-body pt-2OFF pb-2 mt-4OFF mb-4 padMe3">
        {/* MOD AK CONTAINER */}
        <div>
          <Row>
            <Col lg={12}>
              <div className="offer-dedicated-body-left">
                {/* MENU ITEMS */}
                <div>
                  {categories.map((v, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Scroll.Element
                          name={_.snakeCase(v[currentLocale]?.name)}
                        >
                          <h2
                            id={_.snakeCase(v[currentLocale]?.name)}
                            className="mb-4 mt-3 menu-cat-title"
                          >
                            {v[currentLocale]?.name}

                            {/* <small className="h6 text-black-50 pl-1 h7">
                          ({_.isArray(v.items) && v.items.length} items)
                        </small> */}
                          </h2>
                          {/* <div className="bg-white rounded borderOFF shadow-smOFF mb-4"> */}
                          <Row>
                            {_.isArray(v.items) &&
                              v.items.map((itemV, itemI) => {
                                if (
                                  _.isEmpty(filterItems) ||
                                  (!_.isEmpty(filterItems) &&
                                    filterItems[itemV[currentLocale]?.id])
                                )
                                  return (
                                    <Col xl={4} lg={6} md={6} key={itemI}>
                                      <ItemContainer
                                        id={itemV[currentLocale]?.id}
                                        title={itemV[currentLocale]?.name}
                                        localeShareId={
                                          itemV[currentLocale]?.localeShareId
                                        }
                                        subTitle={
                                          itemV[currentLocale]?.description
                                        }
                                        price={itemV[currentLocale]?.price}
                                        image={
                                          itemV[currentLocale]?.photo?.url || ""
                                        }
                                        priceUnit={priceSymbol}
                                        quantity={
                                          itemsQty?.[
                                            itemV[currentLocale]?.localeShareId
                                          ] || 0
                                        }
                                        getValue={getQty}
                                        modifiers={itemV.modifiers}
                                        availability={
                                          itemV[currentLocale]?.availability
                                        }
                                      />
                                    </Col>
                                  );
                                else
                                  return (
                                    <Col xl={4} lg={6} md={6} key={itemI}></Col>
                                  );
                              })}
                          </Row>
                          <br />
                          {/* </div> */}
                        </Scroll.Element>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default OrderOnline;
