import React, { useEffect, useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import OrderType from "./index";
import { CartContext, LocationsContext } from "../../context";
import _ from "lodash";
import ScheduleTime from "../ScheduleTime";
import { useTrans } from "../../hooks";

const OrderTypeModal = () => {
  const transMsg = useTrans();
  const { orderType, cartLineItems } = useContext(CartContext);
  const [show, setShow] = useState(false);

  const { selectedLocation } = useContext(LocationsContext);

  useEffect(() => {
    if (_.isEmpty(selectedLocation)) return;
    if (_.isUndefined(cartLineItems)) return;
    if (_.isEmpty(cartLineItems)) return;

    if (!_.isEmpty(orderType)) return;

    setShow(true);
  }, [orderType, selectedLocation, cartLineItems]);

  // ================================================================
  return (
    <>
      {!_.isEmpty(selectedLocation) && (
        <>
          <Modal
            show={show}
            backdrop="static"
            onHide={() => {
              setShow(false);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title> {transMsg("deliveryOrPickup")} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6>{transMsg("OrderTypeModalContent1")} :</h6>
              <div className="row">
                <div className="col-12 ">
                  <OrderType label={transMsg("method") + ":"} showModal />
                </div>

                <div className="col-12">
                  <ScheduleTime
                    label={transMsg("scheduleTime")}
                    hoursType="auto"
                  />
                </div>
              </div>
              <Button
                className="btn btn-warning btn-block py-3 text-dark text-center dropdown-item btn-checkout bold"
                style={{ width: "100%" }}
                onClick={() => {
                  setShow(false);
                }}
              >
                {transMsg("done")}
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default OrderTypeModal;
