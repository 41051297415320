import React, { useContext, useState } from "react";
import { Container } from "react-bootstrap";

import { LocationsContext } from "../../context";
import RestaurantModal from "./restaurantModal";
import { useBusinessIsOpen } from "../../hooks";
import _ from "lodash";
import { useTrans } from "../../hooks";

const RestaurantDetials = () => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);
  const [showRestaurantModal, setShowRestaurantModal] = useState(false);
  const handleShowRestaurantModal = () => setShowRestaurantModal(true);
  const { dateNow, isOpen, formatTime } = useBusinessIsOpen();

  // ================================================================
  return (
    <>
      <section className="text-center pt-2">
        <Container>
          <div className="row med-text text-grey">
            <div className="col-12 py-1">
              {!_.isEmpty(dateNow) && isOpen ? (
                <>
                  <span className="go-green"> {transMsg("open")} </span> ⋅{" "}
                  {formatTime(dateNow?.dayHours?.openAt)} -{" "}
                  {formatTime(dateNow?.dayHours?.closeAt)}
                </>
              ) : (
                <>
                  <span className="stop-red">{transMsg("closed")} </span> <br />
                  {transMsg("opens")}{" "}
                  {_.capitalize(transMsg(dateNow?.day || "EMPTY"))}{" "}
                  {formatTime(dateNow?.dayHours?.openAt)}
                </>
              )}
            </div>
            <div className="pt-1 col-12 text-capitalize">
              <span>
                {locationsContext?.selectedLocation?.address && (
                  <>
                    {locationsContext.selectedLocation.address.address_1},{" "}
                    {locationsContext.selectedLocation.address.city} &#8226;{" "}
                    <span
                      className="address-link underline"
                      onClick={handleShowRestaurantModal}
                      onKeyPress={() => {}}
                      role="button"
                      tabIndex={0}
                    >
                      {transMsg("moreInfo")}
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
        </Container>
      </section>
      <RestaurantModal
        showModal={showRestaurantModal}
        setShowModal={setShowRestaurantModal}
      />
    </>
  );
};

export default RestaurantDetials;
