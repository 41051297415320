import React, { useContext } from "react";
import { Container, Badge, Modal } from "react-bootstrap";

import Icofont from "../../components/IcoFont";
import { LocationsContext } from "../../context";
import classnames from "classnames";
import { useBusinessIsOpen } from "../../hooks";
import moment from "moment";
import { useTrans } from "../../hooks";
import _ from "lodash";

const RestaurantModal = ({ showModal, setShowModal }) => {
  const transMsg = useTrans();
  const locationsContext = useContext(LocationsContext);
  // BEGIN MODAL
  const { dateNow, isOpen, formatTime, isCloseNextDay } = useBusinessIsOpen();
  const handleClose = () => setShowModal(false);

  // ================================================================
  return (
    <>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton className="loc-modal-header"></Modal.Header>
        <Modal.Body className="modal-body">
          <Container>
            {/* TODO: fix two line on mobile with icon */}
            <h2 className="mb-4"> {transMsg("locationAndHours")} </h2>

            <span>
              <div className="multi-line">
                <Icofont
                  icon="location-pin"
                  size={1}
                  style={{
                    // color: "#E61400",
                    color: "#292929",
                    marginRight: "4px",
                  }}
                />
                <span>
                  {" "}
                  <h6 className="mb-2">
                    {locationsContext.selectedLocation?.name}{" "}
                    {isOpen ? (
                      <>
                        <Badge variant="success" className="ml-1">
                          {_.upperCase(transMsg("open"))}
                        </Badge>{" "}
                      </>
                    ) : (
                      <>
                        <Badge variant="danger" className="ml-1">
                          {_.upperCase(transMsg("closed"))}
                        </Badge>
                      </>
                    )}
                  </h6>
                  {locationsContext.selectedLocation?.address && (
                    <p className="text-black">
                      {locationsContext.selectedLocation.address.address_1},{" "}
                      {locationsContext.selectedLocation.address.city}
                      <br />
                      {locationsContext.selectedLocation.address.province},{" "}
                      {locationsContext.selectedLocation.address.country},{" "}
                      <span className="uppercase">
                        {locationsContext.selectedLocation.address.postalCode}
                      </span>
                    </p>
                  )}
                </span>
              </div>
            </span>

            <div className="text-black mb-3 text-left">
              <Icofont icon="phone" size={1} style={{ color: "#292929" }} />
              <a
                className="text-black ml-1 phone-link"
                href="tel:{locationsContext.selectedLocation?.address?.phone}"
              >
                {locationsContext.selectedLocation?.address?.phone}
              </a>
            </div>
            <div className="mb-2 text-black capitalize">
              {locationsContext.selectedLocation?.businessHours?.days && (
                <>
                  <Icofont
                    icon="clock-time text-black mr-1"
                    size={1}
                    style={{ color: "#292929" }}
                  />
                  <span style={{ fontSize: "16px" }}>{transMsg("hours")} </span>
                  <ul
                    className="list-group list-group-flush text-grey "
                    style={{ fontSize: 14 }}
                  >
                    {locationsContext.selectedLocation.businessHours.days.map(
                      (d, i) => {
                        return (
                          <React.Fragment key={i}>
                            <li
                              className={classnames("list-group-item py-2", {
                                "list-group-item-dark": dateNow.day === d.day,
                              })}
                            >
                              <div className="row">
                                <div className="col-6 text-left">
                                  {transMsg(d?.day || "EMPTY")}{" "}
                                </div>
                                <div className="col-6 text-right">
                                  {d.openAt ? (
                                    <>
                                      {formatTime(d.openAt)} -{" "}
                                      {isCloseNextDay(d.openAt, d.closeAt) && (
                                        <>
                                          {moment()
                                            .days(d.day)
                                            .add(1, "d")
                                            .format("ddd")}
                                          -
                                        </>
                                      )}
                                      {formatTime(d.closeAt)}
                                    </>
                                  ) : (
                                    <>{transMsg("closed")} </>
                                  )}
                                </div>
                              </div>
                            </li>
                          </React.Fragment>
                        );
                      }
                    )}
                  </ul>
                </>
              )}
            </div>

            <iframe
              src={`https://www.google.com/maps?q=${
                locationsContext.selectedLocation?.name +
                " " +
                locationsContext.selectedLocation?.address?.address_1 +
                " " +
                locationsContext.selectedLocation?.address?.city
              }&output=embed`}
              width="100%"
              height="350"
              title="Restaurant's Location"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RestaurantModal;
