import React, { useEffect, useContext, useState } from "react";
import { Container } from "react-bootstrap";

import Layout from "../../components/Layout";
import OrderTypeModal from "../../components/OrderType/modal";
import {
  LocationsContext,
  CartContext,
  GoogleMapsContext,
} from "../../context";
import _ from "lodash";
// import auth from "../../utils/auth";
import { reverseGeocode } from "../../utils/reverseGeocode";
import OrderOnline from "./orderOnline";
import RestaurantBanner from "./banner";
import RestaurantDetials from "./restaurantDetials";
import { useSessionStorage } from "../../hooks";
import OrderType from "../../components/OrderType";
import SelectShareLocation from "../../components/SelectShareLocation";
// import PostalCodeModal from "../../components/modals/PostalCodeModal";

const Detail = ({ pageContext }) => {
  const {
    locations,
    menu,
    seoData,
    layoutProps: { hasMultipleShareLocations, restaurant, ...layoutPropsRest },
  } = pageContext;

  const locationsContext = useContext(LocationsContext);
  const { isLoaded } = useContext(GoogleMapsContext);

  const cartContext = useContext(CartContext);
  const [seen, setSeen] = useSessionStorage(
    "SelectShareLocationHasBeenSeen",
    false
  );

  const [proximityLocationGiven, setProximityLocationGiven] = useState(false);
  const [pos, setPos] = useState({});
  const [postalCode, setPostalCode] = useState("");

  useEffect(() => {
    if (_.isEmpty(locationsContext.selectedLocation)) {
      console.log("Random Location");
      const res = _.sample(locations);
      locationsContext.setSelectedLocation(res);
    }

    if (_.isEmpty(locations)) return;
    locationsContext.setLocations(locations);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPos({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        if (!_.isEmpty(locationsContext.selectedLocation) &&  locations.length > 1)
          return;
      },
      {
        enableHighAccuracy: true,
      }
    );
  }, [locations, locationsContext]);

  useEffect(() => {
    if (_.isEmpty(pos) || !isLoaded) return;
    if (cartContext.orderType === "pickup") return;
    const google = window.google;

    if (seen) return;
    reverseGeocode(pos).then((res) => {
      setPostalCode(
        res.data.results
          .find((result) => result.types[0] === "postal_code")
          ?.address_components.find(
            (component) => component.types[0] === "postal_code"
          )
          ?.long_name.substring(0, 3)
      );
    });


    let googleMapsDestinations = locations.map((location) => ({
      lat: parseFloat(location.address.lat),
      lng: parseFloat(location.address.long),
    }));
    console.log("Calling Distance Matrix from Index");
    let service = new google.maps.DistanceMatrixService();

    service.getDistanceMatrix(
      {
        destinations: googleMapsDestinations,
        origins: [pos],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidTolls: true,
      },
      (response) => {
        let closesetDistance = response.rows[0].elements[0].distance.value;
        let closestLocation = locations[0];
        for (let i = 0; i < response.rows[0].elements.length; i++) {
          if (
            !_.isEmpty(locations[i].deliveryOptionsJson?.[postalCode]) &&
            !locations[i].deliveryOptionsJson?.[postalCode]?.disable
          ) {
            closestLocation = locations[i];
            break;
          }
            const element = response.rows[0].elements[i];
          if (element.distance.value < closesetDistance) {
            closestLocation = locations[i];
          }
        }

        const stripeAccount = restaurant.stripePayment.locations.find(
          (sl) => sl.location.id === closestLocation.id
        );
        closestLocation.stripeAccountId = stripeAccount.connectedAccountId;
        if (!_.isEmpty(closestLocation) && !proximityLocationGiven) {
          locationsContext.setSelectedLocation(closestLocation);
          setProximityLocationGiven(true);
        }
      }
    );
    setSeen(true);
  }, [pos, isLoaded, cartContext.orderType]);

  // ================================================================
  return (
    <>
      {seen || !hasMultipleShareLocations ? (
        <>
          {/* <PostalCodeModal locations={locations} /> */}
          <Layout
            seo={seoData}
            showCart
            showBottomCart
            showOrderType
            hasMultipleShareLocations={hasMultipleShareLocations}
            restaurant={restaurant}
            {...layoutPropsRest}
          >
            <RestaurantBanner restaurant={restaurant} />
            <OrderTypeModal />

            <Container>
              <center>
                <div className="row mt-3">
                  <div className="col-12">
                    <OrderType showModal />
                  </div>
                </div>
              </center>
              <RestaurantDetials />
              <hr />
              <OrderOnline menu={menu} />
            </Container>
          </Layout>
        </>
      ) : (
        <>
          <SelectShareLocation
            restaurant={restaurant}
            seoData={seoData}
            onSelect={() => {
              setSeen(true);
            }}
          />
        </>
      )}
    </>
  );
};

export default Detail;
